import React from 'react';
import GenericPage from '../components/modules/generic/genericPage';
import NoiseWrapper from '../components/modules/wrappers/NoiseWrapper';

const Legales = () => {
  const title = 'Legales';
  const subTitle = 'Conoce más sobre nuestros Términos y condiciones';
  return (
    <NoiseWrapper>
      <GenericPage title={title} subTitle={subTitle} />
    </NoiseWrapper>
  );
};

export default Legales;
